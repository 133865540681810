import React from 'react';
import backgroundImage from './images/background-01.jpg';
import './styles/App.css';

function App() {
    return (
        <div className="app">
            <div className="landing-page" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="content">
                    <h1 className="title">{process.env.REACT_APP_PROJECT_NAME}</h1>
                    <p className="tagline">{process.env.REACT_APP_TAGLINE}</p>
                </div>
            </div>
        </div>
    );
}

export default App; 